@charset "UTF-8";
@font-face {
  font-family: 'CoconRegular';
  src: url("/fonts/cocon/Cocon-Regular_TGD-Font.woff2") format("woff2");
  /* Modern Browsers */
  src: url("/fonts/cocon/Cocon-Regular_TGD-Font.otf") format("opentype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'Cocon';
  src: url("Cocon-Regular_TGD-Font.woff2") format("woff2"), url("Cocon-Regular_TGD-Font.otf") format("opentype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: 'TrebuchetMS';
  src: url(/fonts/Trebuchet/Trebuchet_MS.woff) format("woff"); }

body, html {
  height: 100%;
  font-family: TrebuchetMS, Roboto, arial, sans-serif;
  color: #454545;
  font-size: 15px; }

h1, h2, h3, h4, h5 {
  font-family: CoconRegular; }

h2 {
  font-size: 3em; }

.errormessage, .successmessage {
  margin-bottom: 2em;
  padding: 1em;
  background: pink;
  color: red;
  font-size: 1.3em;
  font-weight: bold; }

.successmessage {
  background: yellow;
  color: #094c8b; }

.container-shadow {
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.15) !important; }

.header {
  padding: 0em 0rem 0.5em; }

.img-in-content {
  margin-bottom: 3em; }

a, a:hover, a:active, a:focus {
  color: #f15a3b; }

a.orange-button, a.orange-button--small, a.orange-button--xs {
  font-size: 2em;
  cursor: pointer;
  display: inline-block;
  line-height: 2.4em;
  color: white;
  padding: 0 2em;
  border: none;
  background: linear-gradient(-45deg, transparent 5px, #f15a3b 5px, #f15a3b);
  white-space: nowrap; }

a.orange-button--small {
  font-size: 1.5em; }

a.orange-button--xs {
  font-size: 1em; }

hr {
  margin: 4em 0;
  border: 0;
  border-top: 1px solid rgba(73, 73, 73, 0.6); }

.big-caption {
  font-size: 1.2em;
  line-height: 1.4em; }
  @media (min-width: 576px) {
    .big-caption {
      font-size: 2em;
      line-height: 1.5em; } }

.stuk-omlaag-md {
  margin-top: 60px; }
  @media (min-width: 768px) {
    .stuk-omlaag-md {
      margin-top: 130px; } }

.bg-apples {
  background: url("/img/www/bg.png") white;
  background-size: 135px; }

.bg-wom-blue {
  background: #3a69ab;
  border-bottom: 5px solid #094c8b; }

.bg-wom-grey {
  background: #ebebeb; }

.bg-wom-faq {
  background: linear-gradient(-45deg, transparent 25px, white 25px, white); }

.bg-lightgreen {
  background: linear-gradient(-45deg, transparent 25px, #d8f0d6 25px, #d8f0d6); }

.bg-yellowgreen {
  background: linear-gradient(-45deg, transparent 25px, #d2d629 25px, #d2d629); }

.bg-darkgreen {
  background: linear-gradient(-45deg, transparent 25px, #518b24 25px, #518b24); }

.bg-blue {
  background: linear-gradient(-45deg, transparent 25px, #2b82c8 25px, #2b82c8); }

.bg-very-lightblue {
  background: linear-gradient(-45deg, transparent 25px, #bedef7 25px, #bedef7); }

.bg-lightblue {
  background: linear-gradient(-45deg, transparent 25px, #c8d4fc 25px, #c8d4fc); }

.bg-bright-orange {
  background: linear-gradient(-45deg, transparent 25px, #f38f37 25px, #f38f37); }

.bg-orange {
  background: linear-gradient(-45deg, transparent 25px, #ea6333 25px, #ea6333); }

.bg-red {
  background: linear-gradient(-45deg, transparent 25px, #e14838 25px, #e14838); }

.bg-pink {
  background: linear-gradient(-45deg, transparent 25px, #e268a5 25px, #e268a5); }

.bg-grey {
  background: linear-gradient(-45deg, transparent 25px, #d8d8d8 25px, #d8d8d8); }

.fa-envelope-o {
  margin-right: 5px; }

.crkbo {
  max-width: 80ch;
  margin-bottom: 2em; }
  .crkbo img {
    height: 70px; }
    .crkbo img:nth-of-type(2) {
      display: none; }
      @media (min-width: 500px) {
        .crkbo img:nth-of-type(2) {
          display: block; } }
  @media (min-width: 768px) {
    .crkbo img {
      height: 80px; }
    .crkbo p {
      font-size: 1.5em; } }
  @media (min-width: 1200px) {
    .crkbo {
      margin: 0; } }

.edwin p {
  font-size: 1.3em; }

.edwin .socials img {
  width: 50px;
  margin: 0 20px 50px 0; }

.intro-text {
  display: flex;
  flex-direction: column; }
  .intro-text p {
    align-self: flex-end;
    font-size: 1em;
    line-height: 1em;
    max-width: 37ch; }

.nav-blocks .one, .nav-blocks .two, .nav-blocks .three {
  min-height: 13em;
  padding: 1em 1em 0 1em;
  background-position: right 10px top 10px;
  background-repeat: no-repeat;
  background-size: 55px; }

.nav-blocks .one {
  background-color: #2b82c8;
  background-image: url(/img/www/navigation/1-knop.png); }

.nav-blocks .two {
  background-color: #f38f37;
  background-image: url(/img/www/navigation/2-knop.png); }

.nav-blocks .three {
  background-color: #518b24;
  background-image: url(/img/www/navigation/3-knop.png); }

.nav-blocks h3 {
  font-size: 1.5em;
  min-height: 3em;
  padding-right: 3em;
  color: white; }

.nav-blocks p {
  font-size: 0.9em;
  margin: 0;
  color: white;
  flex-grow: 1; }

.nav-blocks .link {
  height: 3em; }

.nav-blocks a {
  font-family: CoconRegular;
  color: white;
  padding-left: 1em; }

.traditioneel, .identiek, .continu {
  position: relative; }
  .traditioneel p:first-of-type:before, .identiek p:first-of-type:before, .continu p:first-of-type:before {
    position: absolute;
    left: -12px;
    content: "";
    background-size: 23px 23px;
    display: inline-block;
    width: 23px;
    height: 23px; }

.traditioneel p:first-of-type:before {
  background-image: url(/img/www/navigation/1-knop.png); }

.traditioneel strong {
  color: #2b82c8;
  font-weight: bold; }

.identiek p:first-of-type:before {
  background-image: url(/img/www/navigation/2-knop.png); }

.identiek strong {
  color: #f38f37;
  font-weight: bold; }

.identiek ul {
  font-size: 1em;
  padding: 0; }
  .identiek ul li {
    max-width: 50ch;
    list-style: none;
    background: url(/img/www/navigation/orange-dot.png) no-repeat left 5px;
    padding-left: 1em;
    font-weight: bold; }

.continu p:first-of-type:before {
  background-image: url(/img/www/navigation/3-knop.png); }

.continu strong {
  color: #518b24;
  font-weight: bold; }

#main-wrapper {
  padding-top: 90px; }

.identifier-warning {
  padding: 1em;
  font-size: 1.2em;
  font-weight: bold;
  border: 1px #d2d629 solid;
  background-color: #d8f0d6; }

/*==================================================
 * Social media links
 * https://github.com/jorenvh/laravel-share
 * ===============================================*/
table.share-links {
  margin-bottom: 3em; }
  @media (min-width: 992px) {
    table.share-links {
      width: 100%; } }
  table.share-links td {
    font-size: 80%;
    vertical-align: middle;
    color: #454545;
    border-bottom: 1px solid silver; }
    @media (min-width: 992px) {
      table.share-links td {
        text-align: right; }
        table.share-links td:last-child {
          width: 1%;
          white-space: nowrap; } }
    table.share-links td a {
      color: #454545; }

#social-links {
  margin: 15px; }
  #social-links ul {
    font-size: 165%;
    padding-left: 0; }
    #social-links ul li {
      display: inline-block;
      list-style: none;
      margin-right: 0.7em; }
      #social-links ul li .fa-facebook-official:hover {
        color: #4267B2; }
      #social-links ul li .fa-twitter:hover {
        color: #1DA1F2; }
      #social-links ul li .fa-whatsapp:hover {
        color: #25D366; }

/*==================================================
 * QuickScan
 * ===============================================*/
.quickscan-cursussen .list-group-item.active {
  color: #495057;
  background-color: #bedef7;
  border: none; }

.quickscan-cursussen ul {
  padding: 0 1rem; }

.quickscan-report th, .quickscan-report td {
  padding: 0 2rem 0.5rem 0.5rem; }

.quickscan-report h3 {
  margin-top: 2em; }

.quickscan-report td.result-voldoende, .quickscan-report td.result-goed, .quickscan-report td.result-zeer-goed {
  color: #518b24; }

.quickscan-report td.result-matig {
  color: #ea6333; }

.quickscan-report td.result-onvoldoende {
  color: red; }

.quickscan-report ul {
  list-style-type: "→" !important; }
  .quickscan-report ul li {
    padding-left: 1em; }

@media (min-width: 768px) {
  .quickscan-report .google_graph {
    min-height: 400px; } }

@media (min-width: 992px) {
  .quickscan-report .google_graph {
    min-height: 450px; } }

@media (min-width: 1200px) {
  .quickscan-report .google_graph {
    min-height: 500px; } }

.quickscan-report .graphed_results .bg-bar {
  background-image: linear-gradient(to right, red 0%, green 50%) !important; }

/*==================================================
 * Home page
 * ===============================================*/
#home .home-height {
  display: flex;
  flex-flow: column wrap; }
  @media (min-height: 700px) {
    #home .home-height {
      min-height: calc(100vh - 95px); } }
  #home .home-height .banner {
    position: relative;
    background-image: url(/img/www/hero-home.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex: 1 0 auto;
    min-height: 60vh; }
    #home .home-height .banner .splash {
      position: absolute; }
      @media (max-width: 575px), (max-height: 700px) {
        #home .home-height .banner .splash {
          border-radius: 25px;
          padding: 2em 1.5em;
          font-size: 1em;
          margin: 1em 0 0 1em;
          background-color: #f38f37; } }
      @media (min-width: 576px) and (min-height: 700px) {
        #home .home-height .banner .splash {
          top: 2vh;
          left: 2vw;
          width: 400px;
          height: 150px;
          padding: 2.5em 1em 2em 2.2em;
          background-image: url(/img/www/klodders/bright-orange.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover; } }
      @media (min-width: 768px) and (min-height: 700px) {
        #home .home-height .banner .splash {
          width: 500px;
          height: 200px;
          padding: 3.7em 2em 2em 3em; } }
      #home .home-height .banner .splash p {
        font-family: CoconRegular;
        line-height: 1em;
        font-size: 1.1em;
        color: white;
        margin: 0; }
        @media (min-width: 576px) {
          #home .home-height .banner .splash p {
            margin: 0 0 0.5em 0; } }
        @media (min-width: 768px) {
          #home .home-height .banner .splash p {
            font-size: 1.3em; } }
      #home .home-height .banner .splash sub {
        color: white;
        font-size: 0.9em;
        margin-left: 2em; }
        @media (min-width: 768px) {
          #home .home-height .banner .splash sub {
            font-size: 1em; } }
  #home .home-height .payoff {
    background-color: #d8f0d6;
    box-shadow: inset 0 6px 10px -10px #000;
    padding: 4em 2em 2em;
    flex: 0 1 auto; }
    #home .home-height .payoff div {
      padding: 0 1em; }
      @media (min-width: 768px) {
        #home .home-height .payoff div {
          padding: 0 6em; } }
      @media (min-width: 992px) {
        #home .home-height .payoff div {
          padding: 0 10em; } }
    @media (min-width: 992px) {
      #home .home-height .payoff h1 {
        font-size: 3.5em; } }

#home .subline div {
  padding: 0 3em; }
  @media (min-width: 1200px) {
    #home .subline div {
      padding: 0 14em; } }

#home .subline p {
  font-size: 1.2em; }
  @media (min-width: 992px) {
    #home .subline p {
      font-size: 1.5em; } }

.voordelen {
  font-family: CoconRegular; }
  .voordelen h3 {
    font-size: 1.9em; }
  .voordelen ul {
    font-size: 1.3em;
    padding: 0; }
    .voordelen ul li {
      list-style: none;
      background: url(/img/www/navigation/white-dot.png) no-repeat left 8px;
      padding-left: 1em;
      margin-bottom: 1em;
      font-size: 0.9em; }
      .voordelen ul li a {
        color: white;
        border-bottom: 1px solid;
        text-decoration: none; }

.bereiken {
  font-family: CoconRegular; }
  .bereiken h3 {
    font-size: 2em;
    color: #942b80;
    margin-bottom: 1em; }
  .bereiken ul {
    font-size: 1.3em;
    padding: 0; }
    .bereiken ul li {
      list-style: none;
      background: url(/img/www/navigation/purple-dot.png) no-repeat left 8px;
      padding-left: 1em;
      margin-bottom: 1em; }

.speerpunten {
  font-family: CoconRegular;
  margin: 2em; }
  .speerpunten h3 {
    font-size: 2em;
    color: #e14838;
    margin-bottom: 1em; }
  .speerpunten ul {
    font-size: 1.3em;
    padding: 0; }
    .speerpunten ul li {
      list-style: none;
      background: url(/img/www/navigation/red-dot.png) no-repeat left 8px;
      padding-left: 1em;
      margin-bottom: 1em; }

.contact {
  margin-top: 4em;
  margin-right: -3rem; }
  .contact img {
    margin-left: 8em;
    max-width: 200px; }
  .contact div {
    background: linear-gradient(45deg, transparent 20px, #f38f37 20px, #f38f37);
    font-family: CoconRegular;
    margin-left: 4em;
    padding: 2em;
    font-size: 1em;
    color: white; }
    @media (min-width: 576px) {
      .contact div {
        margin-left: 6em; } }
    .contact div span {
      font-size: 1.1em; }
    .contact div sub {
      top: -0.5em; }
    .contact div a {
      color: white; }

/*==================================================
 * Hoe-ziet-overblijf-eruit
 * ===============================================*/
section.hoe-ziet-overblijf-eruit img {
  margin-bottom: -0.1em; }

section.hoe-ziet-overblijf-eruit h3 {
  margin: 0 0.7em 0 -3rem;
  padding: 0.6em 1em;
  color: white;
  background: linear-gradient(-45deg, transparent 25px, #e14838 25px, #e14838); }
  @media (min-width: 768px) {
    section.hoe-ziet-overblijf-eruit h3 {
      margin-right: 12em; } }
  @media (min-width: 992px) {
    section.hoe-ziet-overblijf-eruit h3 {
      margin-right: 16em;
      padding-right: 5em; } }
  @media (min-width: 1200px) {
    section.hoe-ziet-overblijf-eruit h3 {
      margin-right: 21em;
      padding-right: 8em; } }

section.hoe-ziet-overblijf-eruit h4 {
  font-size: 2.5em; }

section.hoe-ziet-overblijf-eruit p {
  font-family: CoconRegular; }

/*==================================================
 * Over ons
 * ===============================================*/
.vervolg-locatie {
  font-size: 1.3em; }
  .vervolg-locatie h3 {
    margin-top: 2em;
    font-size: 1.8em; }
    .vervolg-locatie h3 img {
      height: 70px; }
  .vervolg-locatie .overblijfmedewerker {
    color: #d2d629;
    font-weight: bold;
    font-size: 1.2em; }
  .vervolg-locatie .sport-en-spel-medewerker, .vervolg-locatie .coordinator {
    color: #e268a5;
    font-weight: bold;
    font-size: 1.2em; }
  .vervolg-locatie .regiomanager {
    color: #f38f37;
    font-weight: bold;
    font-size: 1.2em; }
  .vervolg-locatie .centraal-bureau {
    color: #9c9b9b;
    font-weight: bold;
    font-size: 1.2em; }

/*==================================================
 * Frequently asked Questions
 * ===============================================*/
section.faqs {
  font-family: CoconRegular; }
  section.faqs h2 {
    font-size: 3.5em; }
  section.faqs .faq-question {
    position: relative; }
    section.faqs .faq-question button {
      font-size: 1.1em;
      padding-right: 4em; }
      @media (min-width: 576px) {
        section.faqs .faq-question button {
          font-size: 1.2em; } }
      section.faqs .faq-question button:focus, section.faqs .faq-question button:focus, section.faqs .faq-question button:hover, section.faqs .faq-question button.active {
        box-shadow: none; }
      section.faqs .faq-question button[aria-expanded="true"] img {
        transform: rotate(90deg); }
    section.faqs .faq-question img {
      position: absolute;
      right: 2em;
      top: 1em;
      width: 30px;
      transition: .3s transform ease-in-out;
      transform: rotate(180deg); }
  section.faqs .faq-answer {
    font-size: 1.3em; }

.pagination {
  font-size: 1.6em; }
  .pagination li {
    padding: 0.4em 0.9em; }
    .pagination li a {
      color: #454545; }
    .pagination li.active {
      background: linear-gradient(-45deg, transparent 15px, #2b82c8 15px, #2b82c8);
      color: white; }

.faq-form {
  padding: 1.5em 1.5em 0.5em;
  margin: 3em;
  color: white; }
  .faq-form input {
    width: 100% !important; }
  @media (min-width: 992px) {
    .faq-form {
      width: 50%; } }
  .faq-form label {
    display: none; }
  .faq-form h3 {
    font-size: 2.5em; }

.beloften {
  max-width: 500px;
  margin-right: -3rem; }
  @media (min-width: 992px) {
    .beloften {
      margin-top: -150px; } }
  @media (min-width: 576px) {
    .beloften img {
      margin-left: 8em; } }
  .beloften div {
    background: linear-gradient(45deg, transparent 20px, #f38f37 20px, #f38f37);
    padding: 2em;
    color: white; }
    @media (min-width: 576px) {
      .beloften div h3 {
        font-size: 4em; } }
    .beloften div p {
      margin-top: 2em;
      margin-bottom: 2rem;
      font-size: 1.2em; }
    .beloften div ul {
      font-size: 1.3em;
      padding: 0; }
      .beloften div ul li {
        font-family: CoconRegular;
        font-size: 0.8em;
        list-style: none;
        background: url(/img/www/navigation/white-dot.png) no-repeat left 5px;
        padding-left: 1em;
        margin-bottom: 0.8em; }

/*==================================================
 * Calls to actions
 * ===============================================*/
div.call-to-action {
  margin-right: -3rem; }
  div.call-to-action .banner {
    background-size: 78px;
    background-repeat: no-repeat;
    background-position: 4px center;
    padding: 2em 3em 2em 110px;
    border-radius: 130px 0 0 130px; }
    div.call-to-action .banner p {
      font-family: CoconRegular;
      font-size: 0.8em;
      margin: 0; }
      div.call-to-action .banner p a {
        color: white; }
    @media (min-width: 400px) {
      div.call-to-action .banner p {
        font-size: 1em; } }
    @media (min-width: 576px) {
      div.call-to-action .banner {
        background-size: 80px;
        background-position: 7px center; }
        div.call-to-action .banner p {
          font-size: 1.8em; } }
    @media (min-width: 768px) {
      div.call-to-action .banner {
        background-position: 4px center;
        background-size: 70px; }
        div.call-to-action .banner p {
          font-size: 1em; } }
    @media (min-width: 992px) {
      div.call-to-action .banner {
        background-position: 5px center; }
        div.call-to-action .banner p {
          font-size: 1.4em; } }
    @media (min-width: 1200px) {
      div.call-to-action .banner {
        background-position: 7px center;
        background-size: 80px; }
        div.call-to-action .banner p {
          font-size: 2em; } }
    div.call-to-action .banner.whitepaper {
      margin-top: 3em;
      background-color: #e268a5;
      background-image: url(/img/www/navigation/formulier-knop.png); }
      @media (min-width: 768px) {
        div.call-to-action .banner.whitepaper {
          margin-top: 10em; } }
    div.call-to-action .banner.kennisdocument-roze {
      background-color: #e268a5;
      background-image: url(/img/www/navigation/formulier-knop.png); }
      div.call-to-action .banner.kennisdocument-roze p {
        font-size: 0.7em; }
        @media (min-width: 576px) {
          div.call-to-action .banner.kennisdocument-roze p {
            font-size: 1em; } }
    div.call-to-action .banner.kennisdocument {
      background-color: #d2d629;
      background-image: url(/img/www/navigation/formulier-knop_groen.png); }
      @media (min-width: 576px) {
        div.call-to-action .banner.kennisdocument p {
          font-size: 1.7em; } }
    div.call-to-action .banner.mijn-kinderstralen {
      margin-top: 5em;
      background-color: #d2d629;
      background-image: url(/img/www/navigation/mijn-kinderstralen-knop.png); }
    div.call-to-action .banner.overblijfacademie, div.call-to-action .banner.kinderstralen {
      margin-top: 3em;
      background-color: #ea6333;
      background-image: url(/img/www/navigation/lees-meer-knop.png); }
      @media (min-width: 576px) {
        div.call-to-action .banner.overblijfacademie p, div.call-to-action .banner.kinderstralen p {
          font-size: 1.6em; } }
    @media (max-width: 500px) {
      div.call-to-action .banner.kinderstralen p {
        font-size: 1em; } }
    @media (max-width: 400px) {
      div.call-to-action .banner.kinderstralen p {
        font-size: 0.9em; } }
    div.call-to-action .banner.over-ons {
      background-color: #ea6333;
      background-image: url(/img/www/navigation/over-ons-knop.png); }
    div.call-to-action .banner.meer-weten {
      background-color: #f38f37;
      background-image: url(/img/www/navigation/vraag-teken-knop.png); }
    div.call-to-action .banner.faq {
      background-color: #f38f37;
      background-image: url(/img/www/navigation/vraag-teken-knop.png); }
    div.call-to-action .banner.contact-form {
      background-color: #e268a5;
      background-image: url(/img/www/navigation/info-knop.png); }

/*==================================================
 * Quotes - Text in balloon
 * ===============================================*/
.quote {
  display: flex; }
  @media (min-width: 576px) {
    .quote {
      flex-direction: column; } }
  .quote img {
    display: none; }
    @media (min-width: 400px) {
      .quote img {
        display: block;
        width: 100px; } }
    @media (min-width: 576px) {
      .quote img {
        width: 120px;
        margin: 0 0 -3em 9em; } }
    .quote img.directrice {
      display: none; }
      @media (min-width: 768px) {
        .quote img.directrice {
          display: block;
          width: 200px;
          margin: 0 0 -5em 7em; } }
  .quote div {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    margin: 0; }
    .quote div.blue p {
      background: #2b82c8; }
    .quote div.bright-orange p, .quote div.docent p {
      background: #f38f37; }
    .quote div.darkgreen p {
      background: #518b24; }
    .quote div.lightblue p {
      background: #c8d4fc; }
    .quote div.lightgreen p {
      background: #d8f0d6; }
    .quote div.orange p {
      background: #ea6333; }
      .quote div.orange p span {
        font-size: 1.3em;
        padding-left: 1.2em; }
      .quote div.orange p sub {
        padding-left: 2em; }
    .quote div.pink p {
      background: #e268a5; }
    .quote div.primary-color p {
      background: #f15a3b; }
    .quote div.red p {
      background: #e14838; }
    .quote div.yellowgreen p {
      background: #d2d629; }
      .quote div.yellowgreen p span {
        font-size: 1.3em;
        padding-left: 1.2em; }
      .quote div.yellowgreen p sub {
        padding-left: 2em; }
    .quote div.directrice {
      display: none; }
      @media (min-width: 768px) {
        .quote div.directrice {
          display: block; }
          .quote div.directrice p {
            background: #d2d629;
            padding: 5em 0.5em 5em 3.6em;
            max-width: 36em; }
            .quote div.directrice p span {
              font-size: 1.5em;
              padding-left: 2em; }
            .quote div.directrice p sub {
              padding-left: 4em; } }
    .quote div p {
      padding: 1em;
      max-width: 19em;
      line-height: 1em;
      font-family: CoconRegular;
      font-size: 0.9em; }
      @media (min-width: 576px) {
        .quote div p {
          padding: 3.6em 0.5em 5em 2.4em;
          max-width: 24em;
          line-height: 1.1em; } }
      @media (min-width: 768px) {
        .quote div p {
          padding: 2.8em 1.5em 5em 2em;
          max-width: 24em;
          font-size: 0.8em; } }
      @media (min-width: 992px) {
        .quote div p {
          font-size: 0.9em;
          padding: 3.5em 0.5em 5em 2em; } }
      @media (min-width: 1200px) {
        .quote div p {
          font-size: 1em;
          padding: 4em 0.5em 5em 3.4em; } }
    @media (min-width: 576px) {
      .quote div {
        margin: 0 0 0 3em; }
        .quote div.blue {
          background-image: url(/img/www/klodders/blue.png); }
          .quote div.blue p {
            background: none; }
        .quote div.bright-orange, .quote div.docent {
          background-image: url(/img/www/klodders/bright-orange.png); }
          .quote div.bright-orange p, .quote div.docent p {
            background: none; }
        .quote div.darkgreen {
          background-image: url(/img/www/klodders/darkgreen.png); }
          .quote div.darkgreen p {
            background: none; }
        .quote div.lightblue {
          background-image: url(/img/www/klodders/lightblue.png); }
          .quote div.lightblue p {
            background: none; }
        .quote div.lightgreen {
          background-image: url(/img/www/klodders/lightgreen.png); }
          .quote div.lightgreen p {
            background: none; }
        .quote div.orange {
          background-image: url(/img/www/klodders/orange.png); }
          .quote div.orange p {
            background: none; }
        .quote div.pink {
          background-image: url(/img/www/klodders/pink.png); }
          .quote div.pink p {
            background: none; }
        .quote div.primary-color {
          background-image: url(/img/www/klodders/primary-color.png); }
          .quote div.primary-color p {
            background: none; }
        .quote div.red {
          background-image: url(/img/www/klodders/red.png); }
          .quote div.red p {
            background: none; }
        .quote div.yellowgreen, .quote div.directrice {
          background-image: url(/img/www/klodders/yellowgreen.png); }
          .quote div.yellowgreen p, .quote div.directrice p {
            background: none; } }

/*==================================================
 * Main nav
 * ===============================================*/
.main .navbar {
  box-shadow: 0 6px 10px -10px #000, 0px -3px 10px -10px #000; }
  .main .navbar .nav-link,
  .main .navbar .nav-link:focus,
  .main .navbar .nav-item:focus .nav-link,
  .main .navbar .nav-item:hover .nav-link,
  .main .navbar .nav-item.active,
  .main .navbar .dropdown-item,
  .main .navbar .show > .nav-link {
    color: #f15a3b;
    font-size: 13px; }
    @media (max-width: 1200px) and (min-width: 992px) {
      .main .navbar .nav-link,
      .main .navbar .nav-link:focus,
      .main .navbar .nav-item:focus .nav-link,
      .main .navbar .nav-item:hover .nav-link,
      .main .navbar .nav-item.active,
      .main .navbar .dropdown-item,
      .main .navbar .show > .nav-link {
        font-size: 11.5px; } }
  @media (min-width: 992px) {
    .main .navbar .nav-item.login {
      background-color: #eee;
      margin-left: 2em; } }
  .main .navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,90,59, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
  .main .navbar .navbar-toggler {
    border-color: rgba(241, 90, 59, 0.9); }

/*==================================================
 * Logo
 * ===============================================*/
.logo {
  height: 78px;
  position: static; }
  @media (min-width: 992px) {
    .logo {
      width: 330px;
      position: relative; } }
  .logo > video {
    position: absolute;
    top: -50px;
    left: -55px;
    height: auto;
    max-width: 330px;
    width: 100%;
    border: 0;
    outline: 0; }
    @media (min-width: 992px) {
      .logo > video {
        top: -54px;
        left: -72px; } }
  .logo > img {
    padding: 5px 0;
    height: auto;
    max-width: 185px;
    width: 100%;
    border: 0;
    outline: 0; }

@media (max-width: 576px) {
  .lp .logo > video {
    top: -29px;
    left: -45px;
    max-width: 270px; }
  .lp .logo > img {
    padding: 11px 0;
    max-width: 150px; } }

@media (max-width: 400px) {
  .lp .logo > video {
    top: -24px;
    left: -45px;
    max-width: 250px; }
  .lp .logo > img {
    padding: 13px 0;
    max-width: 132px; } }

.lp .oa {
  width: 150px; }
  @media (min-width: 400px) {
    .lp .oa {
      width: 170px; } }

/*==================================================
 * Breadcrumbs
 * ===============================================*/
.breadcrumbs {
  margin-left: -15px;
  padding: 1em 3.5em 1em 1em;
  background: linear-gradient(-45deg, transparent 25px, #f38f37 25px, #f38f37);
  font-size: 0.8em;
  font-style: italic;
  width: fit-content; }
  @media (min-width: 576px) {
    .breadcrumbs {
      font-size: 1em;
      padding: 1em 4.5em 1em 2em; } }
  .breadcrumbs nav {
    display: inline; }
    .breadcrumbs nav ol {
      margin: 0;
      padding: 0; }
      .breadcrumbs nav ol li {
        display: inline-block;
        padding-right: 10px; }
        .breadcrumbs nav ol li.active {
          border-bottom: 2px solid white;
          color: white; }
        .breadcrumbs nav ol li a {
          color: white; }
      .breadcrumbs nav ol li:first-child:before {
        content: ""; }
      .breadcrumbs nav ol li:before {
        content: "> ";
        letter-spacing: 4px;
        color: white; }

/*==================================================
 * Forms
 * ===============================================*/
.form-row {
  margin: 1.5em 0;
  font-size: 0.9em;
  font-family: CoconRegular; }
  .form-row label {
    font-weight: normal;
    width: 100%; }
  .form-row input, .form-row textarea, .form-row select {
    padding: 0.5em 1em;
    width: 100%; }
  .form-row .twitter-typeahead, .form-row .tt-hint, .form-row .tt-input, .form-row .tt-menu {
    width: 100%; }
  .form-row .tt-menu {
    background: lightgray;
    padding: 1em;
    cursor: pointer; }
  .form-row .tt-hint {
    color: #998; }
  .form-row .tt-menu {
    min-width: 100%;
    white-space: nowrap;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #d4d4d4; }
  .form-row .tt-suggestion {
    padding: 3px 11px;
    line-height: 20px; }
  .form-row .tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #F38E36; }
  .form-row .tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #F38E36; }
  .form-row .tt-suggestion p {
    margin: 0; }
  .form-row .tt-input {
    background: #fff !important; }
  .form-row .opvang {
    width: 70%; }
    .form-row .opvang label {
      float: left;
      width: 20%;
      margin-left: 0; }
      .form-row .opvang label input[type=checkbox] {
        margin-right: 0.5em; }
  .form-row .sex {
    width: 70%; }
    .form-row .sex label {
      float: left;
      width: 25%;
      margin-left: 2em; }
  .form-row #middle_name, .form-row #voornaam {
    margin-bottom: 10px; }
  @media (min-width: 576px) {
    .form-row {
      font-size: 1em; }
      .form-row label {
        width: 29%; }
      .form-row input, .form-row textarea, .form-row select,
      .form-row .twitter-typeahead, .form-row .tt-hint, .form-row .tt-input, .form-row .tt-menu {
        width: 70%; }
      .form-row .tarif, .form-row #last_name, .form-row #achternaam {
        margin-left: 29%; } }
  @media (min-width: 768px) {
    .form-row {
      margin: 1.5em 0;
      font-size: 1.2em; }
      .form-row label {
        font-weight: bold; }
      .form-row #middle_name, .form-row #voornaam {
        width: 26%;
        margin-right: 2px;
        margin-bottom: 0; }
      .form-row #last_name, .form-row #achternaam {
        width: 44%;
        margin-left: 0; } }
  .form-row input[type=file] {
    padding: 0; }
  .form-row input[type=checkbox] {
    width: 1em;
    height: 1em;
    margin-top: 0.3em; }
  .form-row input[type=radio] {
    width: 1em;
    margin-right: 0.5em; }
  .form-row .radio {
    width: 100%; }
    .form-row .radio label {
      width: 99%; }
    @media (min-width: 576px) {
      .form-row .radio {
        margin-left: 29%;
        width: 69%; }
        .form-row .radio:first-of-type {
          margin-left: 0; }
        .form-row .radio label {
          width: 100%; } }
  .form-row button {
    font-size: 1.1em;
    cursor: pointer;
    display: inline-block;
    line-height: 2.4em;
    color: white;
    margin-top: 3em;
    padding: 0 2em;
    border: none;
    background: linear-gradient(-45deg, transparent 5px, #f38f37 5px, #f38f37);
    white-space: nowrap; }
    .form-row button:disabled {
      color: #d8d8d8;
      background: linear-gradient(-45deg, transparent 5px, #9c9b9b 5px, #9c9b9b); }

/*==================================================
 * News
 * ===============================================*/
section.news h2 {
  margin-left: -3rem;
  color: white;
  font-size: 3em;
  width: 7em;
  padding: 0.3em 0 0.3em 1em;
  position: relative;
  background: linear-gradient(-45deg, transparent 25px, #2b82c8 25px, #2b82c8); }
  section.news h2 a {
    color: white; }
  section.news h2 span {
    position: absolute;
    left: 200px;
    top: -65px;
    width: 170px;
    height: 170px;
    background-size: 170px;
    background-position: right center;
    background-repeat: no-repeat; }
  section.news h2 .krant {
    background-image: url(/img/www/animaties/krant.gif); }
  section.news h2 .blogs {
    background-image: url(/img/www/navigation/blog-icon.png);
    left: 210px;
    top: -25px;
    width: 100px;
    height: 100px;
    background-size: 100px; }

section.news div:first-of-type {
  justify-content: space-between; }

section.news .article {
  height: 160px;
  border: 1px solid silver; }
  @media (min-width: 992px) {
    section.news .article:nth-child(2n+1) {
      margin-left: -3rem; }
    section.news .article:nth-child(2n+2) {
      margin-right: -3rem; } }
  section.news .article:nth-child(4n+1) {
    background-color: #e14838; }
  section.news .article:nth-child(4n+2) {
    background-color: #518b24; }
  section.news .article:nth-child(4n+3) {
    background-color: #2b82c8; }
  section.news .article:nth-child(4n+4) {
    background-color: #f38f37; }
  section.news .article .image div {
    height: 158px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  section.news .article .text {
    text-align: right; }
    section.news .article .text p, section.news .article .text a {
      color: white; }
    section.news .article .text p {
      font-size: 0.9em;
      line-height: 1.2em; }
    section.news .article .text h3 {
      font-size: 0.9em;
      min-height: 100px; }
      @media (min-width: 400px) {
        section.news .article .text h3 {
          font-size: 1.4em; } }
      section.news .article .text h3 a {
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; }
    section.news .article .text a {
      font-style: italic;
      font-weight: bold; }

section.ander-nieuws h2 {
  font-size: 3.5em;
  margin-bottom: 1em; }

section.ander-nieuws .news-link {
  padding: 1.5em;
  margin: 0 1em 1em 1em; }
  @media (min-width: 576px) {
    section.ander-nieuws .news-link div {
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: right -5px center;
      background-image: url(/img/www/navigation/pijltje.png); } }
  section.ander-nieuws .news-link a {
    font-family: CoconRegular;
    color: white;
    font-size: 1.2em;
    padding-right: 1em; }
    @media (min-width: 576px) {
      section.ander-nieuws .news-link a {
        font-size: 1.5em; } }

section.ander-nieuws .red-bar {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: #e14838;
  margin: 2em; }
  section.ander-nieuws .red-bar .next span, section.ander-nieuws .red-bar .previous span {
    position: absolute;
    background-size: 50px;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px; }
  section.ander-nieuws .red-bar .next a, section.ander-nieuws .red-bar .previous a {
    padding: 1em 0;
    font-family: CoconRegular;
    color: white;
    font-size: 0.8em;
    line-height: 2.5em; }
    @media (min-width: 576px) {
      section.ander-nieuws .red-bar .next a, section.ander-nieuws .red-bar .previous a {
        font-size: 1.2em; } }
  section.ander-nieuws .red-bar .previous a {
    padding-left: 3em; }
  section.ander-nieuws .red-bar .previous span {
    background-image: url(/img/www/navigation/terug-knop.png);
    top: -0.2em;
    left: -1em; }
  section.ander-nieuws .red-bar .next a {
    padding-right: 3em; }
  section.ander-nieuws .red-bar .next span {
    background-image: url(/img/www/navigation/vooruit-knop.png);
    top: -0.2em;
    right: -1em; }

/*==================================================
 * Activities
 * ===============================================*/
section.activities h2 {
  margin-left: -3rem;
  color: white;
  font-size: 2.5em;
  width: 8em;
  padding: 0.3em 0 0.3em 1em;
  position: relative;
  background: linear-gradient(-45deg, transparent 25px, #d2d629 25px, #d2d629); }
  section.activities h2 a {
    color: white; }
  section.activities h2 span {
    position: absolute;
    left: 250px;
    top: -13px;
    width: 100px;
    height: 100px;
    background-size: 100px;
    background-position: right center;
    background-repeat: no-repeat; }
  @media (min-width: 400px) {
    section.activities h2 {
      font-size: 3em; }
      section.activities h2 span {
        left: 320px; } }
  section.activities h2 .bal {
    background-image: url(/img/www/items/bal-en-potlood.png); }

section.activities div:first-of-type {
  justify-content: space-between; }

section.activities .article {
  height: 160px;
  border: 1px solid silver; }
  @media (min-width: 992px) {
    section.activities .article:nth-child(2n+1) {
      margin-left: -3rem; }
    section.activities .article:nth-child(2n+2) {
      margin-right: -3rem; } }
  section.activities .article:nth-child(4n+1) {
    background-color: #bec714; }
  section.activities .article:nth-child(4n+2) {
    background-color: #d5d005; }
  section.activities .article:nth-child(4n+3) {
    background-color: #aaa501; }
  section.activities .article:nth-child(4n+4) {
    background-color: #518b24; }
  section.activities .article .image {
    float: right;
    width: 85%;
    height: 158px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }

/*==================================================
 * Landing page
 * ===============================================*/
.smcx-embed {
  max-width: 100% !important; }
  .smcx-embed > .smcx-iframe-container {
    max-width: 100% !important; }

.hero-lp {
  background-image: url("/img/www/hero.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .hero-lp h1 {
    color: white;
    text-shadow: 2px 2px 8px #000;
    text-align: center;
    padding: 0.5em 0 3em 0;
    font-size: 2em; }
    @media (min-width: 768px) {
      .hero-lp h1 {
        font-size: 3em; } }
    @media (min-width: 992px) {
      .hero-lp h1 {
        font-size: 4em; } }
  .hero-lp img {
    max-width: 100px; }
    @media (min-width: 576px) {
      .hero-lp img {
        max-width: 125px; } }
    @media (min-width: 768px) {
      .hero-lp img {
        max-width: 150px; } }
    @media (min-width: 992px) {
      .hero-lp img {
        max-width: 200px; } }

.enquete-prijs {
  margin-right: -3rem;
  margin-top: 4em; }
  .enquete-prijs img {
    margin-left: 5em;
    max-width: 300px;
    margin-bottom: -2.8em; }
    @media (min-width: 450px) {
      .enquete-prijs img {
        margin-left: 15em; } }
    @media (min-width: 768px) {
      .enquete-prijs img {
        margin-left: 5em; } }
    @media (min-width: 992px) {
      .enquete-prijs img {
        margin-left: 15em; } }
  .enquete-prijs div {
    background: linear-gradient(45deg, transparent 20px, #2b82c8 20px, #2b82c8);
    margin-left: 7em;
    padding: 2em;
    font-size: 1em;
    color: white; }

.keuze img {
  max-width: 120px;
  margin: 3em;
  height: auto; }
  @media (min-width: 576px) {
    .keuze img {
      max-width: 160px; } }
  @media (min-width: 768px) {
    .keuze img {
      max-width: 160px; } }
  @media (min-width: 992px) {
    .keuze img {
      max-width: 230px; } }

.ouders, .onderwijsprofessionals {
  position: relative;
  text-align: center; }
  .ouders img, .onderwijsprofessionals img {
    display: none; }
    @media (min-width: 768px) {
      .ouders img, .onderwijsprofessionals img {
        display: initial; } }
  .ouders a, .onderwijsprofessionals a {
    position: absolute;
    width: fit-content;
    left: 50%;
    color: white;
    font-family: 'CoconRegular';
    line-height: 1em; }

@media (max-width: 767px) {
  .ouders a {
    font-size: 1.8em;
    padding: 1.3em 1em;
    margin-left: -3rem;
    float: left;
    position: static;
    background: linear-gradient(-45deg, transparent 15px, #F28F34 15px, #F28F34); } }

@media (max-width: 576px) {
  .ouders a {
    font-size: 1.6em; } }

@media (max-width: 460px) {
  .ouders a {
    font-size: 1.3em; } }

@media (min-width: 768px) {
  .ouders a {
    font-size: 1.6em;
    transform: translate(-50%, 550%); } }

@media (min-width: 992px) {
  .ouders a {
    font-size: 2.3em;
    transform: translate(-50%, 540%); } }

@media (min-width: 1200px) {
  .ouders a {
    transform: translate(-50%, 670%); } }

@media (max-width: 767px) {
  .onderwijsprofessionals a {
    background: linear-gradient(45deg, transparent 15px, #6EB536 15px, #6EB536);
    padding: 0.9em 1em;
    margin-right: -3rem;
    float: right;
    font-size: 1.8em;
    position: static; } }

@media (max-width: 576px) {
  .onderwijsprofessionals a {
    font-size: 1.6em; } }

@media (max-width: 460px) {
  .onderwijsprofessionals a {
    font-size: 1.3em; } }

@media (min-width: 768px) {
  .onderwijsprofessionals a {
    font-size: 1.6em;
    transform: translate(-50%, 250%); } }

@media (min-width: 992px) {
  .onderwijsprofessionals a {
    font-size: 2.2em;
    transform: translate(-50%, 253%); } }

@media (min-width: 1200px) {
  .onderwijsprofessionals a {
    transform: translate(-50%, 320%); } }

/*==================================================
 * Hero on default layout
 * ===============================================*/
.hero {
  min-height: calc(70vh - 95px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative; }
  .hero h1 {
    float: right;
    margin-right: 1em;
    clear: both;
    font-size: 2em;
    color: white;
    background: linear-gradient(-45deg, transparent 25px, #2b82c8 25px, #2b82c8);
    padding: 0.5em 1em; }
    .hero h1.h1-on-hero-news {
      font-size: 1.8em;
      background: #2b82c8;
      margin-right: 2em;
      position: relative;
      max-width: 25ch;
      padding-right: 2em; }
      .hero h1.h1-on-hero-news span {
        position: absolute;
        right: -2em;
        bottom: -1.5em;
        width: 100px;
        height: 100px;
        background-size: 100px;
        background-position: right center;
        background-repeat: no-repeat;
        background-image: url(/img/www/navigation/krant.png); }
      @media (min-width: 440px) {
        .hero h1.h1-on-hero-news {
          font-size: 2em; }
          .hero h1.h1-on-hero-news span {
            right: -1.5em; } }
      @media (min-width: 768px) {
        .hero h1.h1-on-hero-news {
          font-size: 3em; }
          .hero h1.h1-on-hero-news span {
            right: -0.5em;
            bottom: -0.5em;
            width: 120px;
            height: 120px;
            background-size: 120px; } }
    @media (min-width: 576px) {
      .hero h1 {
        margin-right: 2em; } }
    @media (min-width: 768px) {
      .hero h1 {
        margin-right: 3em;
        font-size: 3em; } }
    @media (min-width: 992px) {
      .hero h1 {
        margin-right: 4em; } }
    @media (min-width: 1200px) {
      .hero h1 {
        margin-right: 5em; } }

/*==================================================
 * Word overblijf medewerker
 * ===============================================*/
@media (min-width: 992px) and (max-height: 1024px) {
  #banner .carousel-item img {
    height: calc(100vh - 201px);
    object-fit: cover; } }

#banner .cta {
  color: white;
  font-weight: bolder;
  line-height: 3em;
  font-size: 1.3em; }
  @media (min-width: 576px) {
    #banner .cta {
      font-size: 1.5em; } }

#banner a {
  color: #fff;
  font-size: 1em;
  display: block;
  margin: auto;
  padding-top: 22px;
  position: relative; }
  @media (min-width: 576px) {
    #banner a {
      font-size: 1.1em; } }
  #banner a span {
    color: #fff;
    display: block;
    font-size: 2.5em;
    padding-bottom: 15px;
    text-shadow: 4px 1px 3px rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    right: 0;
    top: -15px; }
    @media (min-width: 576px) {
      #banner a span {
        top: -25px; } }

#videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(334px, 1fr));
  grid-gap: 25px; }
  #videos h2, #videos p {
    color: white; }
  #videos .video-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #094c8b;
    border-radius: 5px;
    padding: 10px;
    margin-top: 25px;
    background: #3a69ab; }
    #videos .video-item .content {
      flex: 1 1 auto;
      padding: 5px; }
    #videos .video-item .video-iframe {
      position: relative;
      padding-bottom: 54%;
      height: 0;
      overflow: hidden; }
      #videos .video-item .video-iframe iframe, #videos .video-item .video-iframe object, #videos .video-item .video-iframe embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

#arguments h3 {
  font-size: 1.3em; }
  @media (min-width: 992px) {
    #arguments h3 {
      font-size: 1.6em; } }

#arguments p {
  font-size: 1.2em; }

#map-wrapper {
  height: 590px;
  position: relative; }
  #map-wrapper #map {
    width: 100%;
    height: 590px; }
  #map-wrapper .results {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px 0 0 25px;
    box-sizing: border-box;
    width: 280px;
    font-size: 14px;
    line-height: 20px; }
    #map-wrapper .results .form {
      position: relative;
      padding: 0;
      margin: 0; }
      #map-wrapper .results .form input {
        width: 100%;
        height: 50px;
        font-size: 16px;
        margin-left: 73px;
        padding-left: 25px;
        color: #333;
        box-sizing: border-box;
        border: 1px solid #ccc; }
      #map-wrapper .results .form button {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        line-height: 38px;
        color: #fff;
        padding: 0 30px;
        background-color: #3A69AB;
        border: none #094C8B;
        white-space: nowrap; }

/*==================================================
 * Footer
 * ===============================================*/
footer {
  font-family: CoconRegular;
  color: white;
  font-size: 1.3em;
  background-color: #2b82c8; }
  footer .socials img {
    width: 50px;
    margin: 45px 20px 0 0; }
  @media (min-width: 992px) {
    footer .text-footer-right {
      text-align: right; }
    footer .socials img {
      margin: 45px 0 0 20px; } }
  footer .navbar {
    padding: 0; }
    footer .navbar .navbar-nav {
      width: 100%; }
      footer .navbar .navbar-nav li {
        width: 100%; }
    footer .navbar .nav-link {
      padding: 0; }
      footer .navbar .nav-link:hover, footer .navbar .nav-link:active, footer .navbar .nav-link:focus {
        text-decoration: underline; }
  footer a {
    color: white;
    text-decoration: none; }
    footer a:hover, footer a:active, footer a:focus {
      color: white;
      text-decoration: underline; }
