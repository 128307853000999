$primary-color: #f15a3b;
$secondary-color: #2b82c8;

$text-color: #454545;

$lightgreen: #d8f0d6;
$yellowgreen: #d2d629;
$darkgreen: #518b24;
$blue: $secondary-color;
$lightblue: #c8d4fc;
$very-lightblue: #bedef7;
$bright-orange: #f38f37;
$orange: #ea6333;
$red: #e14838;
$pink: #e268a5;
$purple: #942b80;
$grey: #d8d8d8;
$darkgrey: #9c9b9b;
$wom-blue: #3a69ab;
$wom-border: #094c8b;

@font-face {
  font-family: 'CoconRegular';
  //src: url(/fonts/cocon/cocon-regular.woff2) format("woff2");
  //src: url(/fonts/cocon/cocon-regular.woff) format("woff");
  src: url('/fonts/cocon/Cocon-Regular_TGD-Font.woff2') format('woff2'); /* Modern Browsers */
  src: url('/fonts/cocon/Cocon-Regular_TGD-Font.otf') format('opentype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Cocon';
  src: url('Cocon-Regular_TGD-Font.woff2') format('woff2'), /* Modern Browsers */
  url('Cocon-Regular_TGD-Font.otf') format('opentype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'TrebuchetMS';
  src: url(/fonts/Trebuchet/Trebuchet_MS.woff) format("woff");
}

body, html {
  height: 100%;
  font-family: TrebuchetMS, Roboto, arial, sans-serif;
  color: $text-color;
  font-size: 15px;
}

h1, h2, h3, h4, h5 {
  font-family: CoconRegular;
}

h2 {
  font-size: 3em;
}

.errormessage, .successmessage {
  margin-bottom: 2em;
  padding: 1em;
  background: pink;
  color: red;
  font-size: 1.3em;
  font-weight: bold;
}

.successmessage {
  background: yellow;
  color: $wom-border;
}

.container-shadow {
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.header {
  padding: 0em 0rem 0.5em;
}

.img-in-content {
  margin-bottom: 3em;
}

a, a:hover, a:active, a:focus {
  color: $primary-color;
}

a.orange-button, a.orange-button--small, a.orange-button--xs {
  font-size: 2em;
  cursor: pointer;
  display: inline-block;
  line-height: 2.4em;
  color: white;
  padding: 0 2em;
  border: none;
  background: linear-gradient(-45deg, transparent 5px, $primary-color 5px, $primary-color);
  white-space: nowrap;
}

a.orange-button--small {
  font-size: 1.5em;
}

a.orange-button--xs {
  font-size: 1em;
}

hr {
  margin: 4em 0;
  border: 0;
  border-top: 1px solid rgba(73, 73, 73, 0.6);
}

.big-caption {
  font-size: 1.2em;
  line-height: 1.4em;

  @media (min-width: 576px) {
    font-size: 2em;
    line-height: 1.5em;
  }
}

.stuk-omlaag-md {
  margin-top: 60px;

  @media (min-width: 768px) {
    margin-top: 130px;
  }
}

.bg-apples {
  background: url("/img/www/bg.png") white;
  background-size: 135px;
}

.bg-wom-blue {
  background: $wom-blue;
  border-bottom: 5px solid $wom-border;
}

.bg-wom-grey {
  background: #ebebeb;
}

.bg-wom-faq {
  background: linear-gradient(-45deg, transparent 25px, white 25px, white);
}

.bg-lightgreen {
  background: linear-gradient(-45deg, transparent 25px, $lightgreen 25px, $lightgreen);
}

.bg-yellowgreen {
  background: linear-gradient(-45deg, transparent 25px, $yellowgreen 25px, $yellowgreen);
}

.bg-darkgreen {
  background: linear-gradient(-45deg, transparent 25px, $darkgreen 25px, $darkgreen);
}

.bg-blue {
  background: linear-gradient(-45deg, transparent 25px, $secondary-color 25px, $secondary-color);
}

.bg-very-lightblue {
  background: linear-gradient(-45deg, transparent 25px, $very-lightblue 25px, $very-lightblue);
}

.bg-lightblue {
  background: linear-gradient(-45deg, transparent 25px, $lightblue 25px, $lightblue);
}

.bg-bright-orange {
  background: linear-gradient(-45deg, transparent 25px, $bright-orange 25px, $bright-orange);
}

.bg-orange {
  background: linear-gradient(-45deg, transparent 25px, $orange 25px, $orange);
}

.bg-red {
  background: linear-gradient(-45deg, transparent 25px, $red 25px, $red);
}

.bg-pink {
  background: linear-gradient(-45deg, transparent 25px, $pink 25px, $pink);
}

.bg-grey {
  background: linear-gradient(-45deg, transparent 25px, $grey 25px, $grey);
}

.fa-envelope-o {
  margin-right: 5px;
}

.crkbo {
  max-width: 80ch;
  margin-bottom: 2em;

  img {
    height: 70px;

    &:nth-of-type(2) {
      display: none;

      @media (min-width: 500px) {
        display: block;
      }
    }
  }

  @media (min-width: 768px) {
    img {
      height: 80px;
    }

    p {
      font-size: 1.5em;
    }
  }

  @media (min-width: 1200px) {
    margin: 0;
  }
}

.edwin {
  p {
    font-size: 1.3em;
  }

  .socials {
    img {
      width: 50px;
      margin: 0 20px 50px 0;
    }
  }
}

.intro-text {
  display: flex;
  flex-direction: column;

  p {
    align-self: flex-end;
    font-size: 1em;
    line-height: 1em;
    max-width: 37ch;
  }
}

.nav-blocks {
  .one, .two, .three {
    min-height: 13em;
    padding: 1em 1em 0 1em;

    background-position: right 10px top 10px;
    background-repeat: no-repeat;
    background-size: 55px;
  }

  .one {
    background-color: $blue;
    background-image: url(/img/www/navigation/1-knop.png);
  }

  .two {
    background-color: $bright-orange;
    background-image: url(/img/www/navigation/2-knop.png);
  }

  .three {
    background-color: $darkgreen;
    background-image: url(/img/www/navigation/3-knop.png);
  }

  h3 {
    font-size: 1.5em;
    min-height: 3em;
    padding-right: 3em;
    color: white;
  }

  p {
    font-size: 0.9em;
    margin: 0;
    color: white;
    flex-grow: 1;
  }

  .link {
    height: 3em;
  }

  a {
    font-family: CoconRegular;
    color: white;
    padding-left: 1em;
  }
}

.traditioneel, .identiek, .continu {
  position: relative;

  p:first-of-type:before {
    position: absolute;
    left: -12px;
    content: "";
    background-size: 23px 23px;
    display: inline-block;
    width: 23px;
    height: 23px;
  }
}

.traditioneel {
  p:first-of-type:before {
    background-image: url(/img/www/navigation/1-knop.png);
  }

  strong {
    color: $blue;
    font-weight: bold;
  }
}


.identiek {
  p:first-of-type:before {
    background-image: url(/img/www/navigation/2-knop.png);
  }

  strong {
    color: $bright-orange;
    font-weight: bold;
  }

  ul {
    font-size: 1em;
    padding: 0;

    li {
      max-width: 50ch;
      list-style: none;
      background: url(/img/www/navigation/orange-dot.png) no-repeat left 5px;
      padding-left: 1em;
      font-weight: bold;
    }
  }
}

.continu {
  p:first-of-type:before {
    background-image: url(/img/www/navigation/3-knop.png);
  }

  strong {
    color: $darkgreen;
    font-weight: bold;
  }
}

#main-wrapper {
  //margin-top: 25px;
  padding-top: 90px;
}

.identifier-warning {
  padding: 1em;
  font-size: 1.2em;
  font-weight: bold;
  border: 1px $yellowgreen solid;
  background-color: $lightgreen;
}

/*==================================================
 * Social media links
 * https://github.com/jorenvh/laravel-share
 * ===============================================*/

table.share-links {
  @media (min-width: 992px) {
    width: 100%;
  }

  margin-bottom: 3em;

  td {
    font-size: 80%;
    vertical-align: middle;
    color: #454545;
    border-bottom: 1px solid silver;

    @media (min-width: 992px) {
      text-align: right;

      &:last-child {
        width: 1%;
        white-space: nowrap;
      }
    }

    a {color: #454545;}
  }
}

#social-links {
  margin: 15px;

  ul {
    font-size: 165%;
    padding-left:0;

    li {
      display: inline-block;
      list-style: none;
      margin-right: 0.7em;

      .fa-facebook-official {
        &:hover {color: #4267B2;}
      }

      .fa-twitter {
        &:hover {color: #1DA1F2;}
      }

      .fa-whatsapp {
        &:hover {color: #25D366;}
      }
    }
  }
}

/*==================================================
 * QuickScan
 * ===============================================*/

.quickscan-cursussen {
  .list-group-item.active {
    color: #495057;
    background-color: #bedef7;
    border: none;
  }

  ul {
    padding: 0 1rem;
  }
}

.quickscan-tso-advice {

}

.quickscan-report {

  th, td { padding: 0 2rem 0.5rem 0.5rem; }

  h3 {
    margin-top: 2em;
  }

  td.result-voldoende, td.result-goed, td.result-zeer-goed {
    color: $darkgreen;
  }

  td.result-matig {
    color: $orange;
  }

  td.result-onvoldoende {
    color: red;
  }

  ul {
    list-style-type: "→" !important;

    li {
      padding-left: 1em;
    }
  }

  .google_graph {

    @media (min-width: 768px) {
      min-height: 400px;
    }

    @media (min-width: 992px) {
      min-height: 450px;
    }

    @media (min-width: 1200px) {
      min-height: 500px;
    }
  }

  .graphed_results {
    .bg-bar {
      background-image: linear-gradient(to right, red 0%, green 50%) !important;
    }
  }
}


/*==================================================
 * Home page
 * ===============================================*/

#home {
  .home-height {
    display: flex;
    flex-flow: column wrap;

    @media (min-height: 700px) {
      min-height: calc(100vh - 95px);
    }

    .banner {
      position: relative;
      background-image: url(/img/www/hero-home.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      flex: 1 0 auto;
      min-height: 60vh;

      .splash {
        position: absolute;

        @media (max-width: 575px), (max-height: 700px) {
          border-radius: 25px;
          padding: 2em 1.5em;
          font-size: 1em;
          margin: 1em 0 0 1em;
          background-color: $bright-orange;
        }

        @media (min-width: 576px) and (min-height: 700px) {
          top: 2vh;
          left: 2vw;
          width: 400px;
          height: 150px;
          padding: 2.5em 1em 2em 2.2em;
          background-image: url(/img/www/klodders/bright-orange.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }

        @media (min-width: 768px) and (min-height: 700px) {
          width: 500px;
          height: 200px;
          padding: 3.7em 2em 2em 3em;
        }

        p {
          font-family: CoconRegular;
          line-height: 1em;
          font-size: 1.1em;
          color: white;
          margin: 0;

          @media (min-width: 576px) {
            margin: 0 0 0.5em 0;
          }

          @media (min-width: 768px) {
            font-size: 1.3em;
          }
        }

        sub {
          color: white;
          font-size: 0.9em;
          margin-left: 2em;

          @media (min-width: 768px) {
            font-size: 1em;
          }
        }
      }
    }

    .payoff {
      background-color: $lightgreen;
      box-shadow: inset 0 6px 10px -10px #000;
      padding: 4em 2em 2em;
      flex: 0 1 auto;

      div {
        padding: 0 1em;

        @media (min-width: 768px) {
          padding: 0 6em;
        }

        @media (min-width: 992px) {
          padding: 0 10em;
        }
      }

      h1 {
        @media (min-width: 992px) {
          font-size: 3.5em;
        }
      }
    }
  }

  .subline {
    div {
      padding: 0 3em;

      @media (min-width: 1200px) {
        padding: 0 14em;
      }
    }

    p {
      font-size: 1.2em;

      @media (min-width: 992px) {
        font-size: 1.5em;
      }
    }
  }
}

.voordelen {
  font-family: CoconRegular;

  h3 {
    font-size: 1.9em;
  }

  ul {
    font-size: 1.3em;
    padding: 0;

    li {
      list-style: none;
      background: url(/img/www/navigation/white-dot.png) no-repeat left 8px;
      padding-left: 1em;
      margin-bottom: 1em;
      font-size: 0.9em;

      a {
        color: white;
        border-bottom: 1px solid;
        text-decoration: none;
      }
    }
  }
}

.bereiken {
  font-family: CoconRegular;

  h3 {
    font-size: 2em;
    color: $purple;
    margin-bottom: 1em;
  }

  ul {
    font-size: 1.3em;
    padding: 0;

    li {
      list-style: none;
      background: url(/img/www/navigation/purple-dot.png) no-repeat left 8px;
      padding-left: 1em;
      margin-bottom: 1em;
    }
  }
}

.speerpunten {
  font-family: CoconRegular;
  margin: 2em;

  h3 {
    font-size: 2em;
    color: $red;
    margin-bottom: 1em;
  }

  ul {
    font-size: 1.3em;
    padding: 0;

    li {
      list-style: none;
      background: url(/img/www/navigation/red-dot.png) no-repeat left 8px;
      padding-left: 1em;
      margin-bottom: 1em;
    }
  }
}

.contact {
  margin-top: 4em;
  margin-right: -3rem;

  img {
    margin-left: 8em;
    max-width: 200px;
  }

  div {
    background: linear-gradient(45deg, transparent 20px, $bright-orange 20px, $bright-orange);
    font-family: CoconRegular;
    margin-left: 4em;
    padding: 2em;
    font-size: 1em;
    color: white;

    @media (min-width: 576px) {
      margin-left: 6em;
    }

    span {
      font-size: 1.1em;
    }

    sub {
      top: -0.5em;
    }

    a {
      color: white;
    }
  }
}

/*==================================================
 * Hoe-ziet-overblijf-eruit
 * ===============================================*/

section.hoe-ziet-overblijf-eruit {
  img {
    margin-bottom: -0.1em;
  }

  h3 {
    margin: 0 0.7em 0 -3rem;
    padding: 0.6em 1em;
    color: white;
    background: linear-gradient(-45deg, transparent 25px, $red 25px, $red);

    @media (min-width: 768px) {
      margin-right: 12em;
    }

    @media (min-width: 992px) {
      margin-right: 16em;
      padding-right: 5em;
    }

    @media (min-width: 1200px) {
      margin-right: 21em;
      padding-right: 8em;
    }
  }

  h4 {
    font-size: 2.5em;
  }

  p {
    font-family: CoconRegular;
  }
}

/*==================================================
 * Over ons
 * ===============================================*/

.vervolg-locatie {
  h3 {
    margin-top: 2em;
    font-size: 1.8em;

    img {
      height: 70px;
    }
  }

  font-size: 1.3em;

  .overblijfmedewerker {
    color: $yellowgreen;
    font-weight: bold;
    font-size: 1.2em;
  }

  .sport-en-spel-medewerker, .coordinator {
    color: $pink;
    font-weight: bold;
    font-size: 1.2em;
  }

  .regiomanager {
    color: $bright-orange;
    font-weight: bold;
    font-size: 1.2em;
  }

  .centraal-bureau {
    color: $darkgrey;
    font-weight: bold;
    font-size: 1.2em;
  }
}

/*==================================================
 * Frequently asked Questions
 * ===============================================*/

section.faqs {
  font-family: CoconRegular;

  h2 {
    font-size: 3.5em;
  }

  .faq-question {
    position: relative;

    button {
      font-size: 1.1em;
      padding-right: 4em;

      @media(min-width: 576px) {
        font-size: 1.2em;
      }

      &:focus, &:focus, &:hover, &.active {
        box-shadow: none;
      }

      &[aria-expanded="true"] img {
        transform: rotate(90deg);
      }
    }

    img {
      position: absolute;
      right: 2em;
      top: 1em;
      width: 30px;
      transition: .3s transform ease-in-out;
      transform: rotate(180deg);
    }
  }

  .faq-answer {
    font-size: 1.3em;
  }
}

.pagination {
  font-size: 1.6em;

  li {
    padding: 0.4em 0.9em;

    a {
      color: $text-color;
    }

    &.active {
      background: linear-gradient(-45deg, transparent 15px, $secondary-color 15px, $secondary-color);
      color: white;
    }
  }
}

// FAQ Page

.faq-form {
  padding: 1.5em 1.5em 0.5em;
  margin: 3em;
  color: white;

  input {
    width: 100% !important;
  }

  @media (min-width: 992px) {
    width: 50%;
  }

  label {
    display: none;
  }

  h3 {
    font-size: 2.5em;
  }
}

.beloften {
  max-width: 500px;
  margin-right: -3rem;

  @media (min-width: 992px) {
    margin-top: -150px;
  }

  img {
    @media (min-width: 576px) {
      margin-left: 8em;
    }
  }

  div {
    background: linear-gradient(45deg, transparent 20px, $bright-orange 20px, $bright-orange);
    padding: 2em;
    color: white;

    h3 {
      //font-size: 4em;
      @media (min-width: 576px) {
        font-size: 4em;
      }
    }

    p {
      margin-top: 2em;
      margin-bottom: 2rem;
      font-size: 1.2em;
    }

    ul {
      font-size: 1.3em;
      padding: 0;

      li {
        font-family: CoconRegular;
        font-size: 0.8em;
        list-style: none;
        background: url(/img/www/navigation/white-dot.png) no-repeat left 5px;
        padding-left: 1em;
        margin-bottom: 0.8em;
      }
    }
  }
}

/*==================================================
 * Calls to actions
 * ===============================================*/

div.call-to-action {
  margin-right: -3rem;

  .banner {
    background-size: 78px;
    background-repeat: no-repeat;
    background-position: 4px center;
    padding: 2em 3em 2em 110px;
    border-radius: 130px 0 0 130px;

    p {
      font-family: CoconRegular;
      font-size: 0.8em;
      margin: 0;

      a {
        color: white;
      }
    }

    @media (min-width: 400px) {
      p {
        font-size: 1em;
      }
    }

    @media (min-width: 576px) {
      background-size: 80px;
      background-position: 7px center;
      p {
        font-size: 1.8em;
      }
    }

    @media (min-width: 768px) {
      background-position: 4px center;
      background-size: 70px;
      p {
        font-size: 1em;
      }
    }

    @media (min-width: 992px) {
      background-position: 5px center;
      p {
        font-size: 1.4em;
      }
    }

    @media (min-width: 1200px) {
      background-position: 7px center;
      background-size: 80px;
      p {
        font-size: 2em;
      }
    }

    &.whitepaper {
      margin-top: 3em;
      background-color: $pink;
      background-image: url(/img/www/navigation/formulier-knop.png);

      @media (min-width: 768px) {
        margin-top: 10em;
      }
    }

    &.kennisdocument-roze {
      p {
        font-size: 0.7em;

        @media (min-width: 576px) {
          font-size: 1em;
        }
      }

      background-color: $pink;
      background-image: url(/img/www/navigation/formulier-knop.png);
    }

    &.kennisdocument {
      p {
        @media (min-width: 576px) {
          font-size: 1.7em;
        }
      }

      background-color: $yellowgreen;
      background-image: url(/img/www/navigation/formulier-knop_groen.png);
    }

    &.mijn-kinderstralen {
      margin-top: 5em;
      background-color: $yellowgreen;
      background-image: url(/img/www/navigation/mijn-kinderstralen-knop.png);
    }

    &.overblijfacademie, &.kinderstralen {
      p {
        @media (min-width: 576px) {
          font-size: 1.6em;
        }
      }

      margin-top: 3em;
      background-color: $orange;
      background-image: url(/img/www/navigation/lees-meer-knop.png);
    }

    &.kinderstralen {
      p {
        @media (max-width: 500px) {
          font-size: 1em;
        }

        @media (max-width: 400px) {
          font-size: 0.9em;
        }
      }
    }

    &.over-ons {
      background-color: $orange;
      background-image: url(/img/www/navigation/over-ons-knop.png);
    }

    &.meer-weten {
      background-color: $bright-orange;
      background-image: url(/img/www/navigation/vraag-teken-knop.png);
    }

    &.faq {
      background-color: $bright-orange;
      background-image: url(/img/www/navigation/vraag-teken-knop.png);
    }

    &.contact-form {
      background-color: $pink;
      background-image: url(/img/www/navigation/info-knop.png);
    }
  }
}

/*==================================================
 * Quotes - Text in balloon
 * ===============================================*/

.quote {
  display: flex;

  @media (min-width: 576px) {
    flex-direction: column;
  }

  img {
    display: none;

    @media (min-width: 400px) {
      display: block;
      width: 100px;
    }

    @media (min-width: 576px) {
      width: 120px;
      margin: 0 0 -3em 9em;
    }

    &.directrice {
      display: none;

      @media (min-width: 768px) {
        display: block;
        width: 200px;
        margin: 0 0 -5em 7em;
      }
    }
  }

  div {
    &.blue p {
      background: $blue;
    }

    &.bright-orange p, &.docent p {
      background: $bright-orange;
    }

    &.darkgreen p {
      background: $darkgreen;
    }

    &.lightblue p {
      background: $lightblue;
    }

    &.lightgreen p {
      background: $lightgreen;
    }

    &.orange p {
      background: $orange;

      span {
        font-size: 1.3em;
        padding-left: 1.2em;
      }

      sub {
        padding-left: 2em;
      }
    }

    &.pink p {
      background: $pink;
    }

    &.primary-color p {
      background: $primary-color;
    }

    &.red p {
      background: $red;
    }

    &.yellowgreen p {
      background: $yellowgreen;

      span {
        font-size: 1.3em;
        padding-left: 1.2em;
      }

      sub {
        padding-left: 2em;
      }
    }

    &.directrice {
      display: none;

      @media (min-width: 768px) {
        display: block;

        p {
          background: $yellowgreen;
          padding: 5em 0.5em 5em 3.6em;
          max-width: 36em;

          span {
            font-size: 1.5em;
            padding-left: 2em;
          }

          sub {
            padding-left: 4em;
          }
        }
      }
    }

    p {
      padding: 1em;
      max-width: 19em;
      line-height: 1em;
      font-family: CoconRegular;
      font-size: 0.9em;

      @media (min-width: 576px) {
        padding: 3.6em 0.5em 5em 2.4em;
        max-width: 24em;
        line-height: 1.1em;
      }

      @media (min-width: 768px) {
        padding: 2.8em 1.5em 5em 2em;
        max-width: 24em;
        font-size: 0.8em;
      }

      @media (min-width: 992px) {
        font-size: 0.9em;
        padding: 3.5em 0.5em 5em 2em;
      }

      @media (min-width: 1200px) {
        font-size: 1em;
        padding: 4em 0.5em 5em 3.4em;
      }
    }

    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    margin: 0;

    // swap background
    @media (min-width: 576px) {
      margin: 0 0 0 3em;

      &.blue {
        background-image: url(/img/www/klodders/blue.png);

        p {
          background: none;
        }
      }

      &.bright-orange, &.docent {
        background-image: url(/img/www/klodders/bright-orange.png);

        p {
          background: none;
        }
      }

      &.darkgreen {
        background-image: url(/img/www/klodders/darkgreen.png);

        p {
          background: none;
        }
      }

      &.lightblue {
        background-image: url(/img/www/klodders/lightblue.png);

        p {
          background: none;
        }
      }

      &.lightgreen {
        background-image: url(/img/www/klodders/lightgreen.png);

        p {
          background: none;
        }
      }

      &.orange {
        background-image: url(/img/www/klodders/orange.png);

        p {
          background: none;
        }
      }

      &.pink {
        background-image: url(/img/www/klodders/pink.png);

        p {
          background: none;
        }
      }

      &.primary-color {
        background-image: url(/img/www/klodders/primary-color.png);

        p {
          background: none;
        }
      }

      &.red {
        background-image: url(/img/www/klodders/red.png);

        p {
          background: none;
        }
      }

      &.yellowgreen, &.directrice {
        background-image: url(/img/www/klodders/yellowgreen.png);

        p {
          background: none;
        }
      }
    }
  }
}

/*==================================================
 * Main nav
 * ===============================================*/

.main {
  .navbar {
    box-shadow: 0 6px 10px -10px #000, 0px -3px 10px -10px #000;

    .nav-link,
    .nav-link:focus,
    .nav-item:focus .nav-link,
    .nav-item:hover .nav-link,
    .nav-item.active,
    .dropdown-item,
    .show > .nav-link {
      color: $primary-color;
      font-size: 13px;

      // temp fix for double lines in main menu: https://projects.studio148.nl/browse/MK-227
      @media(max-width: 1200px) and (min-width: 992px) {
        font-size: 11.5px;
      }
    }

    .nav-item.login {
      @media (min-width: 992px) {
        background-color: #eee;
        margin-left: 2em;
      }
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,90,59, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .navbar-toggler {
      border-color: rgba(241, 90, 59, 0.9);
    }
  }
}

/*==================================================
 * Logo
 * ===============================================*/

.logo {
  height: 78px;
  position: static;

  @media (min-width: 992px) {
    width: 330px;
    position: relative;
  }

  > video {
    position: absolute;
    top: -50px;
    left: -55px;
    height: auto;
    max-width: 330px;
    width: 100%;
    border: 0;
    outline: 0;

    @media (min-width: 992px) {
      top: -54px;
      left: -72px;
    }
  }

  > img {
    padding: 5px 0;
    height: auto;
    max-width: 185px;
    width: 100%;
    border: 0;
    outline: 0;
  }
}

.lp {
  .logo {
    @media (max-width: 576px) {
      > video {
        top: -29px;
        left: -45px;
        max-width: 270px;
      }

      > img {
        padding: 11px 0;
        max-width: 150px;
      }
    }

    @media (max-width: 400px) {
      > video {
        top: -24px;
        left: -45px;
        max-width: 250px;
      }

      > img {
        padding: 13px 0;
        max-width: 132px;
      }
    }
  }

  .oa {
    width: 150px;

    @media (min-width: 400px) {
      width: 170px;
    }
  }
}

/*==================================================
 * Breadcrumbs
 * ===============================================*/

.breadcrumbs {
  margin-left: -15px;
  padding: 1em 3.5em 1em 1em;
  background: linear-gradient(-45deg, transparent 25px, $bright-orange 25px, $bright-orange);
  font-size: 0.8em;
  font-style: italic;
  width: fit-content;

  @media (min-width: 576px) {
    font-size: 1em;
    padding: 1em 4.5em 1em 2em;
  }

  nav {
    display: inline;

    ol {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        padding-right: 10px;

        &.active {
          border-bottom: 2px solid white;
          color: white;
        }

        a {
          color: white;
        }
      }

      li:first-child:before {
        content: "";
      }

      li:before {
        content: "> ";
        letter-spacing: 4px;
        color: white;
      }
    }
  }
}

/*==================================================
 * Forms
 * ===============================================*/

.form-row {
  margin: 1.5em 0;
  font-size: 0.9em;
  font-family: CoconRegular;

  label {
    font-weight: normal;
    width: 100%;
  }

  input, textarea, select, {
    padding: 0.5em 1em;
    width: 100%;
  }

  .twitter-typeahead, .tt-hint, .tt-input, .tt-menu { width: 100%; }
  .tt-menu {
    background: lightgray;
    padding: 1em;
    cursor: pointer;
  }

  .tt-hint {color: #998}
  .tt-menu {min-width:100%;white-space:nowrap;padding: 8px 0;background-color:#fff;border:1px solid #d4d4d4}
  .tt-suggestion {padding: 3px 11px;line-height:20px;}
  .tt-suggestion:hover {cursor: pointer;color: #fff;background-color: #F38E36;}
  .tt-suggestion.tt-cursor {color: #fff;background-color: #F38E36;}
  .tt-suggestion p {margin: 0;}
  .tt-input {background:#fff!important;}

  .opvang {
    width: 70%;

    label {
      float: left;
      width: 20%;
      margin-left: 0;

      input[type=checkbox] {
        margin-right: 0.5em;
      }
    }
  }

  .sex {
    width: 70%;

    label {
      float: left;
      width: 25%;
      margin-left: 2em;
    }
  }

  #middle_name, #voornaam {margin-bottom: 10px;}

  @media (min-width: 576px) {
    font-size: 1em;

    label {
      width: 29%;
    }

    input, textarea, select,
    .twitter-typeahead, .tt-hint, .tt-input, .tt-menu {
      width: 70%;
    }

    .tarif, #last_name, #achternaam {
      margin-left: 29%;
    }
  }

  @media (min-width: 768px) {
    margin: 1.5em 0;
    font-size: 1.2em;

    label {
      font-weight: bold;
    }

    #middle_name, #voornaam {width: 26%; margin-right: 2px; margin-bottom: 0;}
    #last_name, #achternaam {width: 44%; margin-left: 0;}
  }

  input[type=file] {
    padding: 0;
  }

  input[type=checkbox] {
    width: 1em;
    height: 1em;
    margin-top: 0.3em;
  }

  input[type=radio] {
    width: 1em;
    margin-right: 0.5em;
  }

  .radio {
    width: 100%;

    label {
      width: 99%;
    }

    @media (min-width: 576px) {
      margin-left: 29%;
      width: 69%;

      &:first-of-type {
        margin-left: 0;
      }

      label {
        width: 100%;
      }
    }
  }

  button {
    font-size: 1.1em;
    cursor: pointer;
    display: inline-block;
    line-height: 2.4em;
    color: white;
    margin-top: 3em;
    padding: 0 2em;
    border: none;
    background: linear-gradient(-45deg, transparent 5px, $bright-orange 5px, $bright-orange);
    white-space: nowrap;

    &:disabled{
      color: $grey;
      background: linear-gradient(-45deg, transparent 5px, $darkgrey 5px, $darkgrey);
    }
  }
}

/*==================================================
 * News
 * ===============================================*/

section.news {
  h2 {
    a {
      color: white;
    }

    margin-left: -3rem;
    color: white;
    font-size: 3em;
    width: 7em;
    padding: 0.3em 0 0.3em 1em;
    position: relative;
    background: linear-gradient(-45deg, transparent 25px, $blue 25px, $blue);

    span {
      position: absolute;
      left: 200px;
      top: -65px;
      width: 170px;
      height: 170px;
      background-size: 170px;
      background-position: right center;
      background-repeat: no-repeat;
    }

    .krant {
      background-image: url(/img/www/animaties/krant.gif);
    }

    .blogs {
      background-image: url(/img/www/navigation/blog-icon.png);
      left: 210px;
      top: -25px;
      width: 100px;
      height: 100px;
      background-size: 100px;
    }
  }

  div:first-of-type {
    justify-content: space-between;
  }

  .article {
    height: 160px;
    border: 1px solid silver;

    @media (min-width: 992px) {
      &:nth-child(2n+1) {
        margin-left: -3rem;
      }
      &:nth-child(2n+2) {
        margin-right: -3rem;
      }
    }

    &:nth-child(4n+1) {
      background-color: $red;
    }

    &:nth-child(4n+2) {
      background-color: $darkgreen;
    }

    &:nth-child(4n+3) {
      background-color: $blue;
    }

    &:nth-child(4n+4) {
      background-color: $bright-orange;
    }

    .image {
      div {
        height: 158px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }

    .text {
      text-align: right;

      p, a {
        color: white;
      }

      p {
        font-size: 0.9em;
        line-height: 1.2em;
      }

      h3 {
        font-size: 0.9em;
        min-height: 100px;

        @media (min-width: 400px) {
          font-size: 1.4em;
        }

        a {
          font-style: normal;
          font-weight: normal;
          text-decoration: none;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      a {
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}

section.ander-nieuws {
  h2 {
    font-size: 3.5em;
    margin-bottom: 1em;
  }

  .news-link {
    padding: 1.5em;
    margin: 0 1em 1em 1em;

    div {
      @media (min-width: 576px) {
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: right -5px center;
        background-image: url(/img/www/navigation/pijltje.png);
      }
    }

    a {
      font-family: CoconRegular;
      color: white;
      font-size: 1.2em;
      padding-right: 1em;

      @media (min-width: 576px) {
        font-size: 1.5em;
      }
    }
  }

  .red-bar {
    display: flex;
    justify-content: space-between;
    position: relative;
    background: $red;
    margin: 2em;

    .next, .previous, {
      span {
        position: absolute;
        background-size: 50px;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
      }

      a {
        padding: 1em 0;
        font-family: CoconRegular;
        color: white;
        font-size: 0.8em;
        line-height: 2.5em;

        @media (min-width: 576px) {
          font-size: 1.2em;
        }
      }
    }

    .previous {
      a {
        padding-left: 3em;
      }

      span {
        background-image: url(/img/www/navigation/terug-knop.png);
        top: -0.2em;
        left: -1em;
      }
    }

    .next {
      a {
        padding-right: 3em;
      }

      span {
        background-image: url(/img/www/navigation/vooruit-knop.png);
        top: -0.2em;
        right: -1em;
      }
    }
  }
}

/*==================================================
 * Activities
 * ===============================================*/

section.activities {
  h2 {
    a {
      color: white;
    }

    margin-left: -3rem;
    color: white;
    font-size: 2.5em;
    width: 8em;
    padding: 0.3em 0 0.3em 1em;
    position: relative;
    background: linear-gradient(-45deg, transparent 25px, $yellowgreen 25px, $yellowgreen);

    span {
      position: absolute;
      left: 250px;
      top: -13px;
      width: 100px;
      height: 100px;
      background-size: 100px;
      background-position: right center;
      background-repeat: no-repeat;
    }

    @media (min-width: 400px) {
      font-size: 3em;
      span {
        left: 320px;
      }
    }

    .bal {
      background-image: url(/img/www/items/bal-en-potlood.png);
    }
  }

  div:first-of-type {
    justify-content: space-between;
  }

  .article {
    height: 160px;
    border: 1px solid silver;

    @media (min-width: 992px) {
      &:nth-child(2n+1) {
        margin-left: -3rem;
      }
      &:nth-child(2n+2) {
        margin-right: -3rem;
      }
    }

    &:nth-child(4n+1) {
      background-color: #bec714;
    }

    &:nth-child(4n+2) {
      background-color: #d5d005;
    }

    &:nth-child(4n+3) {
      background-color: #aaa501;
    }

    &:nth-child(4n+4) {
      background-color: #518b24;
    }

    .image {
      float: right;
      width: 85%;
      height: 158px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}

/*==================================================
 * Landing page
 * ===============================================*/

.smcx-embed {
  max-width: 100% !important;
  //height: 1000px !important;

  > .smcx-iframe-container {
    max-width: 100% !important;
    //height: 965px !important;
  }
}

.hero-lp {
  background-image: url("/img/www/hero.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  h1 {
    color: white;
    text-shadow: 2px 2px 8px #000;
    text-align: center;
    padding: 0.5em 0 3em 0;
    font-size: 2em;

    @media (min-width: 768px) {
      font-size: 3em;
    }

    @media (min-width: 992px) {
      font-size: 4em;
    }
  }

  img {
    max-width: 100px;

    @media (min-width: 576px) {
      max-width: 125px;
    }

    @media (min-width: 768px) {
      max-width: 150px;
    }

    @media (min-width: 992px) {
      max-width: 200px;
    }
  }
}

.enquete-prijs {
  margin-right: -3rem;
  margin-top: 4em;

  img {
    margin-left: 5em;
    max-width: 300px;
    margin-bottom: -2.8em;

    @media (min-width: 450px) {
      margin-left: 15em;
    }

    @media (min-width: 768px) {
      margin-left: 5em;
    }

    @media (min-width: 992px) {
      margin-left: 15em;
    }
  }

  div {
    background: linear-gradient(45deg, transparent 20px, $blue 20px, $blue);
    margin-left: 7em;
    padding: 2em;
    font-size: 1em;
    color: white;
  }
}

.keuze {
  img {
    max-width: 120px;
    margin: 3em;
    height: auto;

    @media (min-width: 576px) {
      max-width: 160px;
    }

    @media (min-width: 768px) {
      max-width: 160px;
    }

    @media (min-width: 992px) {
      max-width: 230px;
    }
  }
}

.ouders, .onderwijsprofessionals {
  position: relative;
  text-align: center;

  img {
    display: none;

    @media (min-width: 768px) {
      display: initial;
    }
  }

  a {
    position: absolute;
    width: fit-content;
    left: 50%;
    color: white;
    font-family: 'CoconRegular';
    line-height: 1em;
  }
}

.ouders {
  a {
    @media (max-width: 767px) {
      font-size: 1.8em;
      padding: 1.3em 1em;
      margin-left: -3rem;
      float: left;
      position: static;
      background: linear-gradient(-45deg, transparent 15px, #F28F34 15px, #F28F34);
    }

    @media (max-width: 576px) {
      font-size: 1.6em;
    }

    @media (max-width: 460px) {
      font-size: 1.3em;
    }

    @media (min-width: 768px) {
      font-size: 1.6em;
      transform: translate(-50%, 550%);
    }

    @media (min-width: 992px) {
      font-size: 2.3em;
      transform: translate(-50%, 540%);
    }

    @media (min-width: 1200px) {
      transform: translate(-50%, 670%);
    }
  }
}

.onderwijsprofessionals {
  a {
    @media (max-width: 767px) {
      background: linear-gradient(45deg, transparent 15px, #6EB536 15px, #6EB536);
      padding: 0.9em 1em;
      margin-right: -3rem;
      float: right;
      font-size: 1.8em;
      position: static;
    }

    @media (max-width: 576px) {
      font-size: 1.6em;
    }

    @media (max-width: 460px) {
      font-size: 1.3em;
    }

    @media (min-width: 768px) {
      font-size: 1.6em;
      transform: translate(-50%, 250%);
    }

    @media (min-width: 992px) {
      font-size: 2.2em;
      transform: translate(-50%, 253%);
    }

    @media (min-width: 1200px) {
      transform: translate(-50%, 320%);
    }
  }
}

/*==================================================
 * Hero on default layout
 * ===============================================*/

.hero {
  min-height: calc(70vh - 95px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  h1 {
    float: right;
    margin-right: 1em;
    clear: both;
    font-size: 2em;
    color: white;
    background: linear-gradient(-45deg, transparent 25px, $blue 25px, $blue);
    padding: 0.5em 1em;

    // News item
    &.h1-on-hero-news {
      font-size: 1.8em;
      background: $blue;
      margin-right: 2em;
      position: relative;
      max-width: 25ch;
      padding-right: 2em;

      span {
        position: absolute;
        right: -2em;
        bottom: -1.5em;
        width: 100px;
        height: 100px;
        background-size: 100px;
        background-position: right center;
        background-repeat: no-repeat;
        background-image: url(/img/www/navigation/krant.png);
      }

      @media (min-width: 440px) {
        font-size: 2em;

        span {
          right: -1.5em;
        }
      }

      @media (min-width: 768px) {
        font-size: 3em;

        span {
          right: -0.5em;
          bottom: -0.5em;
          width: 120px;
          height: 120px;
          background-size: 120px;
        }
      }
    }

    @media (min-width: 576px) {
      margin-right: 2em;
    }

    @media (min-width: 768px) {
      margin-right: 3em;
      font-size: 3em;
    }

    @media (min-width: 992px) {
      margin-right: 4em;
    }

    @media (min-width: 1200px) {
      margin-right: 5em;
    }
  }
}



/*==================================================
 * Word overblijf medewerker
 * ===============================================*/
#banner {
  @media (min-width: 992px) and (max-height: 1024px){
    .carousel-item img {
      height: calc(100vh - 201px);
      object-fit: cover;
    }
  }

  .cta {
    color: white;
    font-weight: bolder;
    line-height: 3em;
    font-size: 1.3em;
    @media (min-width: 576px){
      font-size: 1.5em;
    }
  }

  a {
    color: #fff;
    font-size: 1em;
    display: block;
    margin: auto;
    padding-top: 22px;
    position: relative;
    @media (min-width: 576px){
      font-size: 1.1em;
    }

    span {
      color: #fff;
      display: block;
      font-size: 2.5em;
      padding-bottom: 15px;
      text-shadow: 4px 1px 3px rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 0;
      right: 0;
      top: -15px;
      @media (min-width: 576px){
        top: -25px;
      }
    }
  }
}

#videos {
  h2, p {
    color: white;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(334px, 1fr));
  grid-gap: 25px;

  .video-item {
    display: flex;
    flex-direction: column;
    border: 1px solid $wom-border;
    border-radius: 5px;
    padding: 10px;
    margin-top: 25px;
    background: $wom-blue;

    .content {
      flex: 1 1 auto;
      padding: 5px;
    }

    .video-iframe {
      position: relative;
      padding-bottom: 54%;
      height: 0;
      overflow: hidden;

      iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

#arguments {
  h3 {
    font-size: 1.3em;

    @media (min-width: 992px) {
      font-size: 1.6em;
    }
  }

  p {
    font-size: 1.2em;
  }
}

#map-wrapper {
  height: 590px;
  position: relative;

  #map {
    width: 100%;
    height: 590px;
  }

  .results {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px 0 0 25px;
    box-sizing: border-box;
    width: 280px;
    font-size: 14px;
    line-height: 20px;

    .form {
      position: relative;
      padding: 0;
      margin: 0;

      input {
        width: 100%;
        height: 50px;
        font-size: 16px;
        margin-left: 73px;
        padding-left: 25px;
        color: #333;
        box-sizing: border-box;
        border: 1px solid #ccc;
      }

      button {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;

        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        line-height: 38px;
        color: #fff;
        padding: 0 30px;
        background-color: #3A69AB;
        border: none #094C8B;
        white-space: nowrap;

      }
    }
  }
}

/*==================================================
 * Footer
 * ===============================================*/

footer {
  font-family: CoconRegular;
  color: white;
  font-size: 1.3em;
  background-color: $secondary-color;

  .socials {
    img {
      width: 50px;
      margin: 45px 20px 0 0;
    }
  }

  @media (min-width: 992px) {
    .text-footer-right {
      text-align: right;
    }

    .socials {
      img {
        margin: 45px 0 0 20px;
      }
    }
  }

  .navbar {
    padding: 0;

    .navbar-nav {
      width: 100%;

      li {
        width: 100%;
      }
    }

    .nav-link {
      padding: 0;

      &:hover, &:active, &:focus {
        text-decoration: underline;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;

    &:hover, &:active, &:focus {
      color: white;
      text-decoration: underline;
    }
  }
}